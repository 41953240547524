import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import Footer from "./footer"
import PreviewHeaderArticle from './header/preview-header-article'

// Global styles
import "../styles/global.css"

const PreviewLayout = ({
  headerBackground,
  header,
  author,
  children,
}) => {
  const data = useStaticQuery(graphql`
    query {
      athleganLogo: file(relativePath: { eq: "athlegan-logo.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 768) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div>
      <PreviewHeaderArticle
        header={header}
        background={headerBackground}
        author={author}
      />
      <main className='post'>{children}</main>
      <Footer logoFluid={data.athleganLogo.childImageSharp.fluid} />
    </div>
  )
}

PreviewLayout.propTypes = {
  headerBackground: PropTypes.string,
  header: PropTypes.string,
  author: PropTypes.object,
  children: PropTypes.node.isRequired,
}

export default PreviewLayout
