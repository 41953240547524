import React, { useState, useEffect } from 'react'
import { getAllUrlParams } from '../lib/url-query'
import styled from 'styled-components'
import shortcodes from '../components/shortcodes'
import MDX from '@mdx-js/runtime'
import axiosInstance from '../lib/fetch-wrapper'
import { breakpoints } from "../parameters/theme"
import PreviewLayout from '../components/preview-layout'

const shortcodeComps = data => ({
  ...shortcodes,
  a: shortcodes.MDXLink,
  Image: props => {
    return <img src={data[props.id]} alt='' />
  },
})

const Container = styled.div`
  maxWidth: 600px;
  padding: 20px;
  margin: 0 auto;

  h1 {
    font-size: 1.5em;
  }

  margin-left: auto;
  margin-right: auto;
  margin-top: 4em;
  max-width: ${breakpoints.tablet}px;
  padding: 1em 1em 0;
  overflow-wrap: break-word;

  &::after {
    clear: both;
    content: ".";
    display: block;
    height: 0;
    visibility: hidden;
  }

  & > *:not(:first-child) {
    margin-top: 1em;
  }

  & > a {
    display: block;
  }

  .section-share-buttons {
    clear: both;
    display: flex;
    justify-content: space-evenly;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0 !important;
    max-width: 768px;
    flex-wrap: wrap;

    &::before {
      clear: both;
      content: "";
      display: block;
      height: 2em;
      margin-top: 0;
      width: 100%;
    }

    .button {
      color: #ffffff;
      display: block;
      padding: 1em;
      text-align: center;
      text-decoration: none;
      width: 25%;

      &.facebook {
        background: #3b5998;
      }
      &.twitter {
        background: #1bb2e9;
      }
      &.pinterest {
        background: #c8232c;
      }
    }
  }

  @media (max-width: ${breakpoints.tablet}px) {
    margin-top: 0;

    .section-share-buttons {
      &::before {
        height: 1em;
      }

      .button {
        margin-top: 1em;
        width: 100%;
      }
    }
  }
`

const Preview = (props) => {
  const [article, setPostData] = useState();
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    const urlParams = getAllUrlParams();

    if (urlParams.id) {
      axiosInstance.get('/content/'+ urlParams.id)
        .then(response => setPostData({...response.data}))
        .catch(() => setHasError(true));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!article) {
    return (
      <div>
        <h1>Fetching data...</h1>
      </div>
    )
  }

  if (hasError) {
    return (
      <div>
        <h1>ERROR fetching article... The sadness T_T</h1>
      </div>
    )
  }

  return (
    <PreviewLayout
      header={article.header}
      headerBackground={article.background}
      author={{
        createdAt: article.creationDate || article.createdAt,
        ...article.author,
      }}
    >
      <Container>
        {!!(article && article.body) && (
          <MDX components={shortcodeComps(article.images)}>
            {article.body}
          </MDX>
        )}
      </Container>
    </PreviewLayout>
  )
}

export default Preview
