import axios from 'axios'

const axiosInstance = axios.create()

axiosInstance.interceptors.request.use( async config => {
  // Do something before request is sent
  return {...config,
    baseURL: 'https://api.athlegan.com',
    timeout: 5000,
    headers: {...config.headers,
      Accept: 'application/json',
    }
  };
},  error => {
  // Do something with request error
  return Promise.reject(error);
});

export default axiosInstance
