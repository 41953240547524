import { isBrowser } from './browser-check'

export const getAllUrlParams = url => {
  let queryString = null;

  if (url) {
    queryString = url.split('?')[1]
  } else if (isBrowser()) {
    queryString = window.location.search.slice(1)
  }

  let params = {};

  if (!queryString) {
    return params
  }

  queryString = queryString.split('#')[0];

  let parts = queryString.split('&');

  for (let i = 0; i < parts.length; i++) {
    let a = parts[i].split('=');
    let paramName = a[0];
    let paramValue = 'undefined' === typeof a[1] ? true : a[1];

    if (paramName.match(/\[(\d+)?\]$/)) {
      let key = paramName.replace(/\[(\d+)?\]/, '');

      if (!params[key]) params[key] = [];

      if (paramName.match(/\[\d+\]$/)) {
        let index = /\[(\d+)\]/.exec(paramName)[1];
        params[key][index] = paramValue;
      } else {
        params[key].push(paramValue);
      }
    } else {
      if (!params[paramName]) {
        params[paramName] = paramValue;
      } else if (params[paramName] && typeof params[paramName] === 'string'){
        params[paramName] = [params[paramName]];
        params[paramName].push(paramValue);
      } else {
        params[paramName].push(paramValue);
      }
    }
  }

  return params;
}
