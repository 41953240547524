import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"

import HeaderNav from "./header-nav"

import { breakpoints } from "../../parameters/theme"

const Container = styled.header`
  margin-bottom: 1.5em;
  padding-bottom: 3em;
  padding-top: 3em;
  
  .background img {
    max-height: 100%;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .byline {
    bottom: -3em;
    display: grid;
    font-style: italic;
    grid-auto-rows: 1fr;
    grid-gap: 1em 1em;
    grid-template-columns: 1fr 5fr;
    left: 0;
    margin-bottom: 1em;
    margin-left: auto;
    margin-right: auto;
    max-width: 537.6px;
    padding-left: 1em;
    padding-right: 1em;
    position: absolute;
    right: 0;

    a {
      color: #cccccc;
      text-decoration: none;

      &:active,
      &:hover,
      &:focus {
        color: #ffffff;
        text-decoration: none;
      }
    }
  }

  .avatar {
    border: solid #ffffff;
    border-radius: 50%;
    border-width: 4px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
    grid-column: 1;
    grid-row: 1 / span 2;
    height: 80px;
    overflow: hidden;
    width: 80px;
  }

  .avatar-image {
    object-fit: contain;
    object-position: center center;
    width: 100%;
  }

  .attribution {
    align-self: end;
    grid-column: 2;
    grid-row: 1;
  }

  .tagline {
    align-self: start;
    color: #999999;
    font-size: 0.75em;
    grid-column: 2;
    grid-row: 2;
  }

  .gatsby-image-wrapper {
    height: 100%;
    width: 100%;
  }

  @media (max-width: ${breakpoints.tablet}px) {
    .byline {
      grid-template-columns: 1fr 3fr;
      max-width: unset;
    }

    .avatar {
      border-radius: unset;
      border-width: 0;
      box-shadow: none;
      grid-row: 1;
      height: auto;
      width: auto;
    }

    .avatar-image {
      border: solid #ffffff;
      border-radius: 5%;
      border-width: 2px;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
      max-height: 130px;
      object-fit: cover;
      object-position: center top;
    }

    .attribution {
      align-self: center;
      line-height: 1.5em;

      .date {
        color: #999999;
        display: block;
      }
    }

    .tagline {
      display: none;
    }
  }
`

const PreviewHeaderArticle = ({ header, background, author }) => {
  return (
    <Container>
      <div className="background">
        <img src={background} alt='' />
      </div>
      <HeaderNav />
      <h1 className="title">{header}</h1>
      <div className="byline">
        <figure className="avatar">
          {author.name && (
            <Link to={`/team/${author.slug}`}>
              <img
                className="avatar-image"
                alt={author.name}
                title={author.name}
                src={author.image}
              />
            </Link>
          )}
        </figure>
        <p className="attribution">
        <span className="author">
          By <Link to={`/team/${author.slug}`}>{author.name}</Link>
        </span>{" "}
          <span className="date">on {author.createdAt}</span>
        </p>
        <p className="tagline">{author.tagline}</p>
      </div>
    </Container>
  )
}

export default PreviewHeaderArticle
